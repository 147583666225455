<template>
  <!-- 404页面 -->
  <div>
    <div class="box">
      <div class="banner">
        <div class="banner_img">
          <img src="../../assets/images/network_anomaly.png" alt="您没有权限" />
        </div>
        <div class="banner_text">哎呀…您访问的页面不存在</div>
        <div class="banner_btn">
          <button @click="backClick">返回上一级</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    backClick() {
      this.$router.go(-1);
    },
  },
};
</script>
 
<style lang="less" scoped>
.box {
  width: 100%;
  height: 100%;
  position: relative;
  .banner {
    width: 600px;
    position: absolute;
    top: 150px;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    .banner_img {
      width: 600px;
      height: 390px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .banner_text {
      margin-top: 30px;
      text-align: center;
      font-size: 18px;
      font-weight: 400;
    }
    .banner_btn {
      text-align: center;
      button {
        width: 450px;
        height: 64px;
        margin-top: 30px;
        color: #fff;
        background: linear-gradient(90deg, #ffd62a 0%, #ffa500 100%);
        border-radius: 4px;
        border: 0;
      }
    }
  }
}
</style>